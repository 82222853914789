<template>
  <org-chart
    :zoomin-limit="1"
    :zoomout-limit="1"
    class="family-tree card-width orgchart-container-height node-border"
    v-slot="nodeData"
    :datasource="relativesInfo"
  >
    <template>
      <v-card
        elevation="4"
        @click="selectRelative(nodeData.nodeData)"
        class="pt-0 card-efect"
        :class="
          nodeData.nodeData.kinshipId == 1
            ? 'relative-card-border-patient'
            : nodeData.nodeData.kinshipId % 2
            ? 'relative-card-border-male'
            : 'relative-card-border-female'
        "
      >
        <v-container fluid class="pt-0">
          <v-row
            class="pt-0"
            :class="
              nodeData.nodeData.kinshipId == 1
                ? 'card-title-patient'
                : nodeData.nodeData.kinshipId % 2
                ? 'card-title-male'
                : 'card-title-female'
            "
          >
            <v-col cols="12" class="pt-0 ">
              <span class="text-h5 white--text">
                {{ nodeData.nodeData.kinship }}
              </span>
            </v-col>
          </v-row>
          <v-row class=" border-bottom" v-if="nodeData.nodeData.name">
            <v-col cols="12">
              <span class="text-h5 ">
                {{ nodeData.nodeData.name }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              :class="nodeData.nodeData.description ? 'text-justify' : ''"
            >
              <span>
                {{
                  nodeData.nodeData.description
                    ? nodeData.nodeData.description
                    : "Sin descripción"
                }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </template>
  </org-chart>
</template>

<script>
import OrgChart from "vue-organization-chart";
import "vue-organization-chart/dist/orgchart.css";
import { KINSHIPS } from "@/config/constants";

export default {
  name: "family-tree",
  components: {
    "org-chart": OrgChart
  },
  props: {
    relatives: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      relativesInfo: {}
    };
  },
  methods: {
    selectRelative(relative) {
      let relativeFormatted = {
        id: relative.id,
        name: relative.name,
        content: relative.description,
        kinship: { id: relative.kinshipId, name: relative.kinship }
      };
      this.$emit("selectedRelative", relativeFormatted);
    },
    formatData() {
      let relativesFormatted = [];

      for (let i = 1; i <= KINSHIPS.FATHER_PATERNAL_GRANDFATHER.id; i++) {
        let currentRelative = this.relatives.find(
          relative => relative.kinship.id === i
        );
        let relativeFormatted = {
          id: currentRelative.id,
          name: currentRelative.name,
          description: currentRelative.content,
          kinship: currentRelative.kinship.name,
          kinshipId: currentRelative.kinship.id,
          children: []
        };
        relativesFormatted.push(relativeFormatted);
      }
      for (let i = KINSHIPS.FATHER_PATERNAL_GRANDFATHER.id; i > 1; i--) {
        if (!(i % 2)) {
          relativesFormatted[i / 2 - 1].children.push(
            relativesFormatted[i - 1]
          );
        } else {
          relativesFormatted[(i - 1) / 2 - 1].children.push(
            relativesFormatted[i - 1]
          );
        }
      }
      this.relativesInfo = relativesFormatted[0];
    }
  },
  async mounted() {
    await this.formatData();
    let element = document.getElementsByClassName("family-tree")[0];
    element.scrollLeft = element.scrollWidth / 2 - element.clientWidth / 2;
    element.scrollTop = element.scrollHeight / 2 - element.clientHeight / 2;
  }
};
</script>

<style scoped>
.family-tree ::v-deep .orgchart {
  background-image: linear-gradient(
      90deg,
      rgba(200, 0, 0, 0) 10%,
      transparent 0
    ),
    linear-gradient(rgba(200, 0, 0, 0) 10%, transparent 0) !important;
  height: fit-content;

  -webkit-transform: matrix(0.6144, 0, 0, -0.6144, 0, 0) translateZ(0);
  -webkit-backface-visibility: hidden;
}
.orgchart-container-height {
  height: 670px;
  width: 100%;
}
.node-border ::v-deep .node {
  border-radius: 60px;
}
.card-width ::v-deep .node {
  min-width: 600px !important;
  -webkit-transform: matrix(1, 0, 0, -1, 0, 0) translateZ(0);
  -webkit-backface-visibility: hidden;
}
.relative-card-border-female {
  border: solid 3px var(--v-primary-base);
  border-radius: 60px;
}
.relative-card-border-female:before {
  border-radius: 60px;
}
.relative-card-border-male {
  border: solid 3px rgb(47, 145, 247);
  border-radius: 10px;
}
.relative-card-border-male:before {
  border-radius: 10px;
}
.relative-card-border-patient {
  border: solid 3px var(--v-secondary-base);
  border-radius: 20px;
}
.relative-card-border-patient:before {
  border-radius: 20px;
}
.card-title-female {
  background-color: var(--v-primary-base);
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
}
.card-title-male {
  background: rgb(47, 145, 247);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.card-title-patient {
  background: var(--v-secondary-base);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.border-bottom {
  border-bottom: solid 2px;
}
.card-efect {
  cursor: pointer;
  transition: 0.1s;
}
.card-efect:hover {
  transform: scale(1.05);
}
.text-justify {
  text-align: justify !important;
}
</style>
