<template>
  <v-container class="pa-6 hint-size">
    <v-row v-if="section">
      <v-col
        cols="12"
        :align="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'"
      >
        <div class="text-sm-h5 text-body-1 primary--text">
          {{
            `${section.name}${
              section.status.id === status.ACTIVE.id ? "" : " - (Eliminado)"
            }`
          }}
        </div>
        <h5 class="primary--text text-sm-body-2">
          {{ section.description }}
        </h5>
      </v-col>
    </v-row>
    <v-row>
      <template v-for="(question, index) in section.questions">
        <v-col
          cols="12"
          sm="6"
          align="start"
          v-if="dataFormatted"
          :key="`${index}-question-${question.id}`"
        >
          <div>
            {{
              `${question.name} ${question.required ? "*" : ""} ${
                question.status.id === status.ACTIVE.id ? "" : " - (Eliminado)"
              }`
            }}
          </div>
          <v-textarea
            :key="autogrow"
            v-model.trim="question.shownAnswer"
            readonly
            auto-grow
            rows="1"
            placeholder="No respondido"
            persistent-hint
            :hint="question.hint"
          ></v-textarea>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { QUESTION_TYPES } from "@/config/constants";
import { STATUS } from "@/config/constants";
export default {
  name: "patient-form-section-data",
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      status: STATUS,
      questionTypes: QUESTION_TYPES,
      dataFormatted: false,
      autogrow: false
    };
  },
  methods: {
    forceRerender() {
      this.autogrow = !this.autogrow;
    },
    async formatData() {
      let index = 0;
      for await (let question of this.section.questions) {
        let answer = "";
        if (question.answers?.length) {
          if (question.answers[0].content) {
            if (question.questionType.id === QUESTION_TYPES.SWITCH.id) {
              answer =
                question.answers[0].content === "true" ? "Verdadero" : "Falso";
            } else {
              answer = question.answers[0].content;
            }
          } else {
            for await (let optionAnswer of question.answers[0].optionAnswers) {
              if (!answer) {
                answer =
                  optionAnswer.option.name +
                  (optionAnswer.option.status.id === STATUS.ACTIVE.id
                    ? ""
                    : " (Eliminado)");
              } else {
                answer +=
                  ", " +
                  optionAnswer.option.name +
                  (optionAnswer.option.status.id === STATUS.ACTIVE.id
                    ? ""
                    : " (Eliminado)");
              }
            }
          }
        }
        this.section.questions[index] = { ...question, shownAnswer: answer };
        index++;
      }
      this.dataFormatted = true;
    }
  },
  mounted() {
    if (this.section) {
      this.formatData();
    }
  }
};
</script>

<style scoped>
.hint-size ::v-deep .v-messages__message {
  line-height: 1.375rem;
  font-size: 0.875rem;
}
</style>
