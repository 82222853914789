<template>
  <v-container fluid class="pa-6">
    <template v-for="(level, hierarchyIndex) in hierarchy">
      <span :key="`${hierarchyIndex}-level-${level}`">
        <h4 class="text-sm-h4 secondary--text">
          {{ level.name }}
        </h4>
        <v-row class="mb-5" :key="`${hierarchyIndex}-level-${level}`">
          <v-col
            cols="12"
            :md="level.relatives.length > 1 ? '6' : '12'"
            v-for="(relative, index) in level.relatives"
            :key="`${index}-${relative.id}`"
          >
            <v-card
              elevation="4"
              class="align-card-height card-efect"
              :class="
                relative.kinship.id == kinships.ME.id
                  ? 'relative-card-border-patient'
                  : relative.kinship.id % 2
                  ? 'relative-card-border-male'
                  : 'relative-card-border-female'
              "
              @click="selectRelative(relative)"
            >
              <v-container fluid class="pt-0">
                <v-row
                  class="pt-0"
                  :class="
                    relative.kinship.id == kinships.ME.id
                      ? 'card-title-patient'
                      : relative.kinship.id % 2
                      ? 'card-title-male'
                      : 'card-title-female'
                  "
                >
                  <v-col cols="12">
                    <span class="text-sm-h5 text-body-1 white--text">
                      {{ relative.kinship.name }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class=" border-bottom" v-if="relative.name">
                  <v-col cols="12">
                    <span class="text-sm-h5 text-h6">
                      {{ relative.name }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="text-justify">
                    <span class="text-body-2 text-sm-body-1">
                      {{
                        relative.content ? relative.content : "Sin descripción"
                      }}
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row></span
      >
    </template>
  </v-container>
</template>

<script>
import { KINSHIPS } from "@/config/constants";

export default {
  name: "family-tree-card",
  props: {
    relatives: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      kinships: KINSHIPS,
      hierarchy: [
        {
          name: "Consultante",
          relatives: []
        },
        {
          name: "Padres",
          relatives: []
        },
        {
          name: "Abuelos",
          relatives: []
        },
        {
          name: "Bisabuelos",
          relatives: []
        }
      ]
    };
  },
  methods: {
    selectRelative(relative) {
      this.$emit("selectedRelative", relative);
    },
    formatData() {
      let grandParents = [];
      let greatGrandParents = [];
      this.relatives.forEach(relative => {
        if (relative.kinship.id === KINSHIPS.ME.id) {
          this.hierarchy[0].relatives.push(relative);
        } else {
          if (
            relative.kinship.id === KINSHIPS.MOTHER.id ||
            relative.kinship.id === KINSHIPS.FATHER.id
          ) {
            this.hierarchy[1].relatives.push(relative);
          } else {
            if (
              relative.kinship.id >= KINSHIPS.MATERNAL_GRANDMOTHER.id &&
              relative.kinship.id <= KINSHIPS.PATERNAL_GRANDFATHER.id
            ) {
              grandParents.push(relative);
              if (grandParents.length == 2) {
                grandParents.sort((r1, r2) => r1.kinship.id - r2.kinship.id);
                this.hierarchy[2].relatives.push(grandParents[0]);
                this.hierarchy[2].relatives.push(grandParents[1]);
                grandParents = [];
              }
            } else {
              if (
                relative.kinship.id >=
                  KINSHIPS.MOTHER_MATERNAL_GRANDMOTHER.id &&
                relative.kinship.id <= KINSHIPS.FATHER_PATERNAL_GRANDFATHER.id
              ) {
                greatGrandParents.push(relative);
                if (greatGrandParents.length === 2) {
                  greatGrandParents.sort(
                    (r1, r2) => r1.kinship.id - r2.kinship.id
                  );
                  this.hierarchy[3].relatives.push(greatGrandParents[0]);
                  this.hierarchy[3].relatives.push(greatGrandParents[1]);
                  greatGrandParents = [];
                }
              }
            }
          }
        }
      });
    }
  },
  mounted() {
    this.formatData();
  }
};
</script>

<style scoped>
.relative-card-border-female {
  border: solid 3px var(--v-primary-base);
  border-radius: 10px;
}
.relative-card-border-male {
  border: solid 3px rgb(47, 145, 247);
  border-radius: 10px;
}
.relative-card-border-patient {
  border: solid 3px var(--v-secondary-base);
  border-radius: 10px;
}
.card-title-female {
  background-color: var(--v-primary-base);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.card-title-male {
  background: rgb(47, 145, 247);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.card-title-patient {
  background: var(--v-secondary-base);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.align-card-height {
  height: 100%;
  align-self: stretch;
}

.card-efect {
  cursor: pointer;
  transition: 0.1s;
}
.card-efect:hover {
  transform: scale(1.05);
}
.text-justify {
  text-align: justify !important;
}
</style>
