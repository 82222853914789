<template>
  <v-container class="pa-6">
    <v-row>
      <v-col>
        <div
          class="d-flex justify-center justify-sm-space-between align-center flex-wrap"
        >
          <v-btn class="mb-6" color="secondary" @click="showModal = true"
            >Añadir multimedia</v-btn
          >
          <p class="text-center">
            Archivos multimedia almacenados: {{ numberOfMultimediaFiles }}
          </p>
        </div>
        <hr />
      </v-col>
    </v-row>
    <v-row v-if="numberOfMultimediaFiles == 0 && loading == false">
      <v-col>
        <empty-section
          title="No hay archivos multimedia almacenados para este consultante"
          subtitle="Agregue un nuevo archivo para visualizar"
          icon="mdi-folder-remove-outline"
        />
      </v-col>
    </v-row>
    <v-row v-if="loading == true">
      <v-col class="d-flex justify-center align-center">
        <v-progress-circular
          :size="200"
          :width="7"
          class="mt-6"
          color="secondary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-if="numberOfMultimediaFiles > 0 && loading == false">
      <v-col>
        <patient-multimedia-file
          v-for="(file, index) in files"
          :key="index"
          :file="file.file"
          :date="file.date"
          :type="file.type"
          :patientId="file.patientId"
          :filename="file.filename"
          :history="file.history"
          @loadMultimediaFiles="loadMultimediaFiles"
        />
      </v-col>
    </v-row>
    <patient-multimedia-modal
      :show-modal="showModal"
      :patient-name="`${patient.firstName} ${patient.lastName}`"
      @closeMultimediaModal="closeMultimediaModal"
      @loadMultimediaFiles="loadMultimediaFiles"
    />
  </v-container>
</template>

<script>
import EmptySection from "../common/notifications/EmptySection";
import PatientMultimediaModal from "./PatientMultimediaModal";
import PatientMultimediaFile from "./PatientMultimediaFile";
import Repository from "@/services/repositories/repositoryFactory";
const PatientRepository = Repository.get("patient");

export default {
  name: "patient-multimedia",
  components: {
    "empty-section": EmptySection,
    "patient-multimedia-modal": PatientMultimediaModal,
    "patient-multimedia-file": PatientMultimediaFile
  },
  props: {
    patient: {
      type: Object,
      required: true
    },
    inTab: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      numberOfMultimediaFiles: 0,
      showModal: false,
      files: [],
      loading: false
    };
  },
  methods: {
    closeMultimediaModal() {
      this.showModal = false;
    },
    async loadMultimediaFiles() {
      this.files = [];
      this.numberOfMultimediaFiles = 0;
      this.loading = true;
      const files = await PatientRepository.getPatientMultimediaFiles(
        this.$route.params.id
      );

      files.forEach(file => {
        if (file.split("-")[2] !== undefined) {
          this.files.push({
            file: `${PatientRepository.getRoute()}/${file}`,
            filename: file,
            date: file.split("-")[1],
            type: file.split("-")[2].split(".")[1],
            patientId: this.$route.params.id,
            history: true
          });
        } else {
          this.files.push({
            file: `${PatientRepository.getRoute()}/${file}`,
            filename: file,
            date: file.split("-")[1].split(".")[0],
            type: file.split("-")[1].split(".")[1],
            patientId: this.$route.params.id,
            history: false
          });
        }
      });

      this.numberOfMultimediaFiles = this.files.length;
      this.loading = false;
    }
  },
  mounted() {
    this.loadMultimediaFiles();
  },
  watch: {
    inTab: async function(val) {
      if (val) {
        await this.loadMultimediaFiles();
      }
    }
  }
};
</script>

<style scoped></style>
