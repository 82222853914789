<template>
  <span class="pa-4">
    <v-container fluid class="pa-6">
      <v-row class="py-2">
        <v-col
          cols="12"
          sm="6"
          class=" grey--text text-h5"
          :class="$vuetify.breakpoint.smAndDown ? 'pt-0' : 'py-0 pr-0'"
          :align="$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
        >
          <div>
            <v-btn
              class="'mr-10"
              :class="succesfullySaved ? 'white--text' : ''"
              @click="saveRecord"
              :color="succesfullySaved ? 'green' : 'secondary'"
              :loading="loading"
              >{{ succesfullySaved ? "Guardado" : "Guardar"
              }}<v-icon v-if="succesfullySaved">mdi-check</v-icon></v-btn
            >
            <p class="my-6 last-save">
              Último guardado: {{ lastSave !== null ? lastSave : "N/A" }}
            </p>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="grey--text text-h5"
          :class="$vuetify.breakpoint.smAndDown ? '' : 'py-0 pr-8'"
          :align="$vuetify.breakpoint.smAndDown ? 'center' : 'end'"
        >
          <p>{{ currentLength + " / " + maxLength }}</p>
          <p class="my-6 custom-link" @click="generateReport">
            Descargar historia del paciente
          </p>
          <vue-html2pdf
            :show-layout="false"
            :enable-download="true"
            :paginate-elements-by-height="1400"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="80%"
            ref="html2Pdf"
            :html-to-pdf-options="{
              margin: 10,
              filename: patientFullName + '-history-' + Date.now(),
              enableLinks: true,
              image: {
                type: 'jpeg',
                quality: 1
              },
              html2canvas: {
                scale: 1,
                useCORS: true
              }
            }"
            @hasDownloaded="dialog = false"
          >
            <section slot="pdf-content">
              <div v-html="contentToDownload"></div>
            </section>
          </vue-html2pdf>
        </v-col>
      </v-row>
      <v-row class="py-0">
        <v-col cols="12" class="py-0">
          <vue-editor
            ref="magicEditor"
            id="editor"
            :editor-toolbar="customToolbar"
            :editorOptions="editorSettings"
            v-model="content"
            @text-change="contentChange"
            class="document-editor document-editor__editable-container"
            use-custom-image-handler
            @image-added="handleEditorImage"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Generando y descargando PDF
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";
import QuillImageDropAndPaste from "quill-image-drop-and-paste";
import EditorImage from "../../mixins/editor-images";
import Repository from "@/services/repositories/repositoryFactory";
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
const PatientRepository = Repository.get("patient");

Quill.register("modules/imageDropAndPaste", QuillImageDropAndPaste);

export default {
  name: "patient-notes",
  mixins: [EditorImage],
  components: {
    "vue-editor": VueEditor,
    "vue-html2pdf": VueHtml2pdf
  },
  props: {
    record: {
      type: Object,
      required: true
    },
    patientFullName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      content: "",
      disabled: true,
      maxLength: 200000,
      currentLength: 0,
      loading: false,
      lastSave: null,
      lastContent: "",
      succesfullySaved: false,
      interval: null,
      dialog: false,
      contentToDownload: "",
      editorSettings: {
        modules: {
          imageDropAndPaste: {
            handler: this.customHanlder
          }
        }
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [
          { align: [] },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["link", "image", "video"],
        ["clean"]
      ]
    };
  },
  methods: {
    async generateReport() {
      this.contentToDownload = this.content.replaceAll(
        "img src",
        'img style="width: 700px !important; margin-left: 8px;" src'
      );
      this.dialog = true;
      await this.$refs.html2Pdf.generatePdf();
    },
    async saveRecord() {
      this.loading = true;
      let recordToUpdate = {
        id: this.record.id,
        content: this.content ? this.content : ""
      };
      await PatientRepository.updateRecord(recordToUpdate)
        .then(() => {
          this.succesfullySaved = true;
          this.lastSave = moment().format("DD-MM-YYYY HH:mm:ss A");
        })
        .finally(() => {
          this.loading = false;
          setTimeout(() => {
            this.succesfullySaved = false;
          }, 3000);
        });
    },
    contentChange(d) {
      if (!this.loading) {
        this.succesfullySaved = false;
      }
      let editor = Quill.find(document.getElementById("editor"));
      let range = editor.getSelection(true);
      this.currentLength = editor.getLength();
      if (editor.getLength() > this.maxLength) {
        let numberOfCharactersToDelete = 0;
        if (d?.ops.length) {
          d.ops.forEach(unitDelta => {
            if (unitDelta?.insert?.length) {
              numberOfCharactersToDelete += unitDelta.insert.length;
            }
          });
        } else {
          numberOfCharactersToDelete = 1;
        }
        if (numberOfCharactersToDelete == 1) {
          editor.deleteText(range.index - 1, numberOfCharactersToDelete);
        } else {
          editor.deleteText(range.index, numberOfCharactersToDelete);
        }
      }
    },
    async customHanlder(imageDataUrl, type, imageData) {
      const fileToSave = this.renameFileForEditorPasteAndDrop(
        imageData,
        this.$route.params.id
      );

      await PatientRepository.uploadFile(fileToSave.file).then(() => {
        const url = `${PatientRepository.getRoute()}/${fileToSave.name}`;
        const quillEditor = this.$refs.magicEditor.$data.quill;
        let index = (quillEditor.getSelection() || {}).index;
        if (index === undefined || index < 0) index = quillEditor.getLength();
        quillEditor.insertEmbed(index, "image", url, "user");
      });
    }
  },
  mounted() {
    if (this.record?.content) {
      this.content = this.record.content;
      this.lastContent = this.content;
    }

    this.interval = setInterval(async () => {
      if (this.lastContent !== this.content) {
        await this.saveRecord();
        this.lastContent = this.content;
        this.lastSave = moment().format("DD-MM-YYYY HH:mm:ss A");
      }
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
};
</script>

<style scoped>
.document-editor {
  border: 1px solid white;
  border-radius: 20px;

  /* Set vertical boundaries for the document editor. */
  max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}
.document-editor__editable-container ::v-deep #editor {
  padding: 20px;
  background: gray;

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
}
.document-editor__editable-container ::v-deep .ql-editor {
  /* Set the dimensions of the "page". */
  width: 21cm;
  min-height: 25.4cm;

  /* Keep the "page" off the boundaries of the container. */
  padding: 1cm 2cm 2cm;

  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: 0px;
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  /* Center the "page". */
  margin: 0 auto;
}

.last-save {
  font-size: 18px;
  text-decoration: underline;
}

.custom-link {
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;
}
</style>
