var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-6",attrs:{"fluid":""}},[_vm._l((_vm.hierarchy),function(level,hierarchyIndex){return [_c('span',{key:(hierarchyIndex + "-level-" + level)},[_c('h4',{staticClass:"text-sm-h4 secondary--text"},[_vm._v(" "+_vm._s(level.name)+" ")]),_c('v-row',{key:(hierarchyIndex + "-level-" + level),staticClass:"mb-5"},_vm._l((level.relatives),function(relative,index){return _c('v-col',{key:(index + "-" + (relative.id)),attrs:{"cols":"12","md":level.relatives.length > 1 ? '6' : '12'}},[_c('v-card',{staticClass:"align-card-height card-efect",class:relative.kinship.id == _vm.kinships.ME.id
                ? 'relative-card-border-patient'
                : relative.kinship.id % 2
                ? 'relative-card-border-male'
                : 'relative-card-border-female',attrs:{"elevation":"4"},on:{"click":function($event){return _vm.selectRelative(relative)}}},[_c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pt-0",class:relative.kinship.id == _vm.kinships.ME.id
                    ? 'card-title-patient'
                    : relative.kinship.id % 2
                    ? 'card-title-male'
                    : 'card-title-female'},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-sm-h5 text-body-1 white--text"},[_vm._v(" "+_vm._s(relative.kinship.name)+" ")])])],1),(relative.name)?_c('v-row',{staticClass:" border-bottom"},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-sm-h5 text-h6"},[_vm._v(" "+_vm._s(relative.name)+" ")])])],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"text-justify",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-body-2 text-sm-body-1"},[_vm._v(" "+_vm._s(relative.content ? relative.content : "Sin descripción")+" ")])])],1)],1)],1)],1)}),1)],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }