<template>
  <v-container class="pa- hint-size">
    <v-row>
      <v-col
        cols="12"
        :align="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'"
      >
        <div class="text-sm-h5 primary--text">
          Información personal
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" align="start">
        <div>Primer nombre</div>
        <v-textarea
          auto-grow
          rows="1"
          v-model.trim="patient.firstName"
          readonly
          required
          placeholder="No respondido"
        ></v-textarea>
      </v-col>
      <v-col cols="12" sm="6" align="start">
        <div>Segundo nombre</div>
        <v-textarea
          auto-grow
          rows="1"
          v-model.trim="patient.middleName"
          readonly
          persistent-hint
          hint="Opcional"
          placeholder="No respondido"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" align="start">
        <div>Primer apellido</div>
        <v-textarea
          auto-grow
          rows="1"
          v-model.trim="patient.lastName"
          readonly
          required
          placeholder="No respondido"
        ></v-textarea>
      </v-col>
      <v-col cols="12" sm="6" align="start">
        <div>Segundo apellido</div>
        <v-textarea
          auto-grow
          rows="1"
          v-model.trim="patient.secondLastName"
          readonly
          persistent-hint
          hint="Opcional"
          placeholder="No respondido"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" align="start">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <div>Fecha de nacimiento</div>
            <v-text-field
              v-model="computedDateFormatted"
              hint="DD/MM/AAAA"
              persistent-hint
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              class="my-0"
              placeholder="Seleccione"
              required
            ></v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="date"
            no-title
            readonly
            :max="maxDate()"
            @input="menu = false"
            color="primary"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="6" align="start">
        <div>Lugar de nacimiento</div>
        <v-textarea
          auto-grow
          rows="1"
          v-model.trim="patient.placeOfBirth"
          readonly
          required
          placeholder="No respondido"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" align="start">
        <div>Correo Electrónico</div>
        <v-textarea
          auto-grow
          rows="1"
          v-model.trim="patient.email"
          readonly
          required
          placeholder="No respondido"
        ></v-textarea>
      </v-col>
      <v-col cols="12" sm="6" align="start">
        <div>Tlf. celular</div>
        <v-textarea
          auto-grow
          rows="1"
          v-model.trim="patient.phone"
          readonly
          required
          placeholder="(424) 456-7890"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" align="start">
        <div>Género</div>
        <v-select
          :items="genderOptions"
          return-object
          v-model="patient.gender"
          readonly
          attach
          placeholder="Seleccione"
          required
        ></v-select>
      </v-col>
      <v-col cols="12" md="6" align="start">
        <div>Profesión u oficio</div>
        <v-textarea
          auto-grow
          rows="1"
          v-model.trim="patient.profession"
          readonly
          required
          placeholder="Escriba"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" align="start">
        <div>Estado civil</div>
        <v-select
          :items="civilStatusOptions"
          return-object
          v-model="patient.civilStatus"
          readonly
          placeholder="Seleccione"
          required
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="start">
        <div>
          Tiempo de relación de pareja / separación / fallecimiento. Comenta su
          nombre completo, profesión y si tuvieron hijos.
        </div>
        <v-textarea
          auto-grow
          rows="1"
          v-model.trim="patient.relationship"
          readonly
          placeholder="No respondido"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" align="start">
        <div>¿Tiene hijos?</div>
        <v-select
          :items="childrenOptions"
          return-object
          v-model="patient.children"
          readonly
          label=""
          placeholder="Seleccione"
          required
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="start">
        <div>
          ¿Has tenido pérdidas o abortos? Comenta brevemente lo que sucedió.
          Aplica si eres hombre.
        </div>
        <v-textarea
          auto-grow
          rows="1"
          v-model.trim="patient.childrenLosses"
          readonly
          placeholder="No respondido"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="start">
        <div>
          De la unión de mamá y papá biológicos. ¿Qué número de hij@ eres?
        </div>
        <v-select
          :items="childPositionOptions"
          return-object
          v-model="patient.childPosition"
          readonly
          placeholder="Seleccione"
          required
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="start">
        <div>
          Si en la respuesta anterior seleccionaste OTRO. Indica cuál número de
          hijo eres
        </div>
        <v-text-field
          v-model="patient.childPositionAlt"
          placeholder="No respondido"
          readonly
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="start">
        <div>
          ¿Sabes si mamá tuvo una pérdida o aborto, el lugar que ocupa y cómo
          fue?
        </div>
        <v-textarea
          auto-grow
          rows="1"
          v-model.trim="patient.motherLosses"
          readonly
          placeholder="No respondido"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="start">
        <div>
          ¿Cómo fue / es la relación de pareja entre papá y mamá? Detalla.
        </div>
        <v-textarea
          auto-grow
          rows="1"
          v-model.trim="patient.parentsRelationship"
          readonly
          placeholder="No respondido"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "patient-basic-data",
  props: {
    patient: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      date: "",
      menu: false,
      genderOptions: ["Hombre", "Mujer", "Otro"],
      civilStatusOptions: [
        "Solter@",
        "Casad@",
        "Unión libre / Concubinato",
        "Divorciad@",
        "Viud@",
        "Otro"
      ],
      childrenOptions: ["Sí", "No", "En gestación"],
      childPositionOptions: [
        "Hij@ únic@",
        "Primer@",
        "Segund@",
        "Tercer@",
        "Cuart@",
        "Quint@",
        "Otro"
      ]
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    maxDate() {
      let date = new Date();
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate()
      );
    }
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    }
  },
  watch: {
    computedDateFormatted(newValue) {
      this.patient.birthdate = newValue;
    }
  },
  mounted() {
    if (this.patient) {
      const [day, month, year] = this.patient.birthdate.split("/");
      this.date = `${year}-${month}-${day}`;
    }
  }
};
</script>

<style scoped>
.hint-size ::v-deep .v-messages__message {
  line-height: 1.375rem;
  font-size: 0.875rem;
}
</style>
