<template>
  <v-container class="pa-3 mb-3 fileBorder">
    <v-row v-if="loaded">
      <v-col>
        <v-progress-linear indeterminate color="secondary"></v-progress-linear>
      </v-col>
    </v-row>
    <v-row v-if="allowImage || allowPDF">
      <v-col>
        <v-img :src="file" v-if="allowImage" @load="fileLoaded"></v-img>
        <pdf
          :src="file"
          v-if="allowPDF"
          style="border: 1px solid gray;"
          @loaded="fileLoaded"
        ></pdf>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div
          class="d-flex justify-center justify-sm-space-between align-center flex-wrap"
        >
          <div class="mx-1">
            <p class="text-center text-sm-left">Fecha: {{ formatedDate }}</p>
            <p class="text-center text-sm-left">Tipo de archivo: {{ type }}</p>
            <v-chip label color="primary" v-if="history"
              >Agregado desde el historial</v-chip
            >
            <v-chip label v-else>Agregado desde multimedia</v-chip>
          </div>
          <div class="mx-1">
            <v-btn color="secondary" class="mb-3" :href="file" block
              >Descargar</v-btn
            >
            <v-btn color="error" block @click="dialog = true">Eliminar</v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Eliminar archivo
        </v-card-title>

        <v-card-text class="mt-3 text-center">
          ¿Está seguro que desea eliminar el archivo de la historia del
          consultante de forma permanente?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteFile"
            :loading="deleteLoading"
          >
            Eliminar archivo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import pdf from "vue-pdf";
import Repository from "@/services/repositories/repositoryFactory";
const moment = require("moment");
const PatientRepository = Repository.get("patient");

export default {
  name: "patient-multimedia-file",
  components: {
    pdf
  },
  props: {
    file: String,
    date: String,
    type: String,
    patientId: String,
    filename: String,
    history: Boolean
  },
  data() {
    return {
      deleteLoading: false,
      dialog: false,
      loaded: true
    };
  },
  methods: {
    async deleteFile() {
      this.deleteLoading = true;
      await PatientRepository.deleteFile(this.filename);
      this.deleteLoading = false;
      this.$emit("loadMultimediaFiles");
    },
    fileLoaded() {
      this.loaded = false;
    }
  },
  computed: {
    allowImage() {
      let type = this.type.toLowerCase();
      if (
        type == "png" ||
        type == "jpg" ||
        type == "jpeg" ||
        type == "svg" ||
        type == "gif" ||
        type == "webp"
      ) {
        return true;
      } else {
        return false;
      }
    },
    allowPDF() {
      let type = this.type.toLowerCase();
      if (type == "pdf") {
        return true;
      } else {
        return false;
      }
    },
    formatedDate() {
      let timestamp = parseInt(this.date);
      return moment.unix(timestamp).format("DD/MM/YYYY hh:mm:ss A");
    }
  },
  mounted() {}
};
</script>

<style scoped>
.fileBorder {
  border: 1px solid black;
}
</style>
