import Repository from "@/services/repositories/repositoryFactory";
const PatientRepo = Repository.get("patient");
const moment = require("moment");

const EditorImage = {
  methods: {
    async handleEditorImage(file, Editor, cursorLocation, resetUploader) {
      console.log(file);
      const fileToSave = this.renameFileForEditor(file, this.$route.params.id);

      await PatientRepo.uploadFile(fileToSave.file).then(() => {
        const url = `${PatientRepo.getRoute()}/${fileToSave.name}`;
        Editor.insertEmbed(cursorLocation, "image", url);
        resetUploader();
      });
    },
    renameFileForEditor(file, id) {
      let newFile = new FormData();
      let filename =
        id +
        "-" +
        moment().unix() +
        "-EDITOR." +
        file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length);

      newFile.append("file", file, filename);
      return { file: newFile, name: filename };
    },
    renameFileForEditorPasteAndDrop(file, id) {
      let newFile = new FormData();
      let filename =
        id +
        "-" +
        moment().unix() +
        "-EDITOR." +
        file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length);

      newFile.append("file", this.dataURItoBlob(file.dataUrl), filename);
      return { file: newFile, name: filename };
    },
    dataURItoBlob(dataURI) {
      let byteString = atob(dataURI.split(",")[1]);
      let mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      let blob = new Blob([ab], { type: mimeString });
      return blob;
    }
  }
};

export default EditorImage;
