<template>
  <patients-detail :id="id" />
</template>

<script>
import PatientsDetail from "@/components/patients/PatientsDetail.vue";

export default {
  name: "patients-detail-view",
  components: {
    "patients-detail": PatientsDetail
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {};
  }
};
</script>

<style scoped></style>
