var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"pa-4"},[_c('v-container',{staticClass:"pa-6",attrs:{"fluid":""}},[_c('v-row',{staticClass:"py-2"},[_c('v-col',{staticClass:" grey--text text-h5",class:_vm.$vuetify.breakpoint.smAndDown ? 'pt-0' : 'py-0 pr-0',attrs:{"cols":"12","sm":"6","align":_vm.$vuetify.breakpoint.smAndDown ? 'center' : 'start'}},[_c('div',[_c('v-btn',{staticClass:"'mr-10",class:_vm.succesfullySaved ? 'white--text' : '',attrs:{"color":_vm.succesfullySaved ? 'green' : 'secondary',"loading":_vm.loading},on:{"click":_vm.saveRecord}},[_vm._v(_vm._s(_vm.succesfullySaved ? "Guardado" : "Guardar")),(_vm.succesfullySaved)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()],1),_c('p',{staticClass:"my-6 last-save"},[_vm._v(" Último guardado: "+_vm._s(_vm.lastSave !== null ? _vm.lastSave : "N/A")+" ")])],1)]),_c('v-col',{staticClass:"grey--text text-h5",class:_vm.$vuetify.breakpoint.smAndDown ? '' : 'py-0 pr-8',attrs:{"cols":"12","sm":"6","align":_vm.$vuetify.breakpoint.smAndDown ? 'center' : 'end'}},[_c('p',[_vm._v(_vm._s(_vm.currentLength + " / " + _vm.maxLength))]),_c('p',{staticClass:"my-6 custom-link",on:{"click":_vm.generateReport}},[_vm._v(" Descargar historia del paciente ")]),_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"enable-download":true,"paginate-elements-by-height":1400,"pdf-quality":2,"manual-pagination":false,"pdf-format":"a4","pdf-orientation":"landscape","pdf-content-width":"80%","html-to-pdf-options":{
            margin: 10,
            filename: _vm.patientFullName + '-history-' + Date.now(),
            enableLinks: true,
            image: {
              type: 'jpeg',
              quality: 1
            },
            html2canvas: {
              scale: 1,
              useCORS: true
            }
          }},on:{"hasDownloaded":function($event){_vm.dialog = false}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.contentToDownload)}})])])],1)],1),_c('v-row',{staticClass:"py-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('vue-editor',{ref:"magicEditor",staticClass:"document-editor document-editor__editable-container",attrs:{"id":"editor","editor-toolbar":_vm.customToolbar,"editorOptions":_vm.editorSettings,"use-custom-image-handler":""},on:{"text-change":_vm.contentChange,"image-added":_vm.handleEditorImage},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Generando y descargando PDF "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }