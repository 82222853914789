<template>
  <v-dialog
    v-model="showModal"
    transition="dialog-bottom-transition"
    persistent
    fullscreen
  >
    <v-card>
      <v-toolbar dark color="primary" class="toolbar">
        <v-btn icon dark @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ titleToShow }}</v-toolbar-title>
      </v-toolbar>
      <br />
      <v-card-text>
        <v-container class="mt-7">
          <v-row>
            <v-col align="center" justify="center">
              <patient-form
                :formPatient="formPatient"
                :update="update"
                flat
                primary
                adminView
                @loadPatients="loadPatients"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PatientForm from "@/components/forms/PatientForm";

export default {
  name: "patient-modal",
  components: {
    "patient-form": PatientForm
  },
  props: {
    showModal: { type: Boolean, required: true },
    titleToShow: { type: String, required: true },
    update: { type: Boolean, default: false },
    formPatient: { type: Number, default: 0 }
  },
  data() {
    return {};
  },
  methods: {
    loadPatients() {
      this.$emit("loadPatients");
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal", false);
    }
  }
};
</script>

<style scoped>
.toolbar {
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;
}
</style>
