<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8" sm="4">
        <v-skeleton-loader type="heading"></v-skeleton-loader>
      </v-col>
    </v-row>
    <br />
    <v-row>
      <v-col cols="12" sm="2" md="1" align="center">
        <v-skeleton-loader type="avatar"></v-skeleton-loader>
      </v-col>
      <v-col
        cols="12"
        sm="10"
        md="11"
        :align="this.$vuetify.breakpoint.xsOnly ? 'center' : 'start'"
      >
        <v-skeleton-loader type="heading"></v-skeleton-loader>
      </v-col>
    </v-row>
    <br />
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader tile type="image"></v-skeleton-loader>
        <v-skeleton-loader tile type="image"></v-skeleton-loader>
        <v-skeleton-loader tile type="image"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "patients-detail-loader",
  data() {
    return {};
  }
};
</script>

<style scoped></style>
