<template>
  <v-dialog
    v-model="showModal"
    transition="dialog-bottom-transition"
    persistent
    fullscreen
  >
    <v-card>
      <v-toolbar dark color="primary" class="toolbar">
        <v-btn icon dark @click="closeMultimediaModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title
          >Agregar contenido multimedia a la historia de
          {{ patientName }}</v-toolbar-title
        >
      </v-toolbar>
      <br />
      <v-card-text>
        <v-container class="mt-7">
          <v-form ref="multimedia">
            <v-row>
              <v-col>
                <v-alert
                  border="top"
                  dark
                  color="secondary"
                  class="text-center"
                  v-if="files.length == 0 && !loading"
                  >Seleccione los archivos que desea cargar en la historia del
                  consultante</v-alert
                >

                <v-alert
                  border="top"
                  dark
                  color="secondary"
                  class="text-center"
                  v-if="files.length > 0 && !loading"
                  >{{ files.length }} archivos para cargar</v-alert
                >

                <v-alert
                  border="top"
                  dark
                  color="secondary"
                  class="text-center"
                  v-if="files.length > 0 && loading"
                  >Cargando archivos ({{ uploadedFiles }}/{{
                    files.length
                  }})</v-alert
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center" justify="center">
                <v-file-input
                  v-model="files"
                  :rules="[rules.required]"
                  multiple
                  show-size
                  outlined
                  hide-hint
                  placeholder="Seleccione uno o varios archivos multimedia"
                >
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn color="secondary" :loading="loading" @click="saveFiles"
                  >Guardar archivos multimedia</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
    <success-message
      :showModal="showSuccessMessage"
      :title="successTitle"
      :message="successMessage"
      @okAction="successfulTransaction()"
    />
  </v-dialog>
</template>

<script>
import SuccessMessage from "@/components/common/notifications/SuccessMessage";
import Repository from "@/services/repositories/repositoryFactory";
const PatientRepository = Repository.get("patient");
const moment = require("moment");

export default {
  name: "patient-multimedia-modal",
  components: {
    "success-message": SuccessMessage
  },
  props: {
    showModal: Boolean,
    patientName: String
  },
  data() {
    return {
      rules: {
        required: value => !!value || "Campo Obligatorio"
      },
      files: [],
      uploadedFiles: 0,
      loading: false,
      showSuccessMessage: false,
      successTitle: "",
      successMessage: ""
    };
  },
  methods: {
    closeMultimediaModal() {
      this.$emit("closeMultimediaModal", false);
    },
    async saveFiles() {
      if (this.$refs.multimedia.validate()) {
        this.loading = true;
        try {
          for (let index = 0; index < this.files.length; index++) {
            const fileToSave = this.renameFile(
              this.files[index],
              this.$route.params.id,
              this.uploadedFiles + 1
            );

            await PatientRepository.uploadFile(fileToSave)
              .then(() => {
                this.uploadedFiles = this.uploadedFiles + 1;
              })
              .catch(error => {
                console.log(error);
              });
          }

          this.uploadedFiles = 0;
          this.files = [];
          this.createdSuccessfully();
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      }
    },
    renameFile(file, id, order) {
      let newFile = new FormData();
      let filename =
        id +
        "-" +
        (moment().unix() + order) +
        "." +
        file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length);

      newFile.append("file", file, filename);
      return newFile;
    },
    createdSuccessfully() {
      this.successTitle = "¡Se han agregado archivos exitosamente!";
      this.successMessage =
        "Los archivos podrán ser visualizado en el historial del paciente actual";
      this.showSuccessMessage = true;
      this.file = null;
      this.$refs.multimedia.reset();
    },
    successfulTransaction() {
      this.$emit("loadMultimediaFiles");
      this.showSuccessMessage = false;
      this.closeMultimediaModal();
    }
  }
};
</script>

<style scoped></style>
