var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('org-chart',{staticClass:"family-tree card-width orgchart-container-height node-border",attrs:{"zoomin-limit":1,"zoomout-limit":1,"datasource":_vm.relativesInfo},scopedSlots:_vm._u([{key:"default",fn:function(nodeData){return [[_c('v-card',{staticClass:"pt-0 card-efect",class:nodeData.nodeData.kinshipId == 1
          ? 'relative-card-border-patient'
          : nodeData.nodeData.kinshipId % 2
          ? 'relative-card-border-male'
          : 'relative-card-border-female',attrs:{"elevation":"4"},on:{"click":function($event){return _vm.selectRelative(nodeData.nodeData)}}},[_c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pt-0",class:nodeData.nodeData.kinshipId == 1
              ? 'card-title-patient'
              : nodeData.nodeData.kinshipId % 2
              ? 'card-title-male'
              : 'card-title-female'},[_c('v-col',{staticClass:"pt-0 ",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h5 white--text"},[_vm._v(" "+_vm._s(nodeData.nodeData.kinship)+" ")])])],1),(nodeData.nodeData.name)?_c('v-row',{staticClass:" border-bottom"},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h5 "},[_vm._v(" "+_vm._s(nodeData.nodeData.name)+" ")])])],1):_vm._e(),_c('v-row',[_c('v-col',{class:nodeData.nodeData.description ? 'text-justify' : '',attrs:{"cols":"12"}},[_c('span',[_vm._v(" "+_vm._s(nodeData.nodeData.description ? nodeData.nodeData.description : "Sin descripción")+" ")])])],1)],1)],1)]]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }