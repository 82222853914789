<template>
  <div>
    <v-breadcrumbs :items="items">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <patients-detail-loader v-if="loading" />
    <error-view v-else-if="errorLoading" />
    <span v-else-if="patient">
      <v-container fluid class="content-relative">
        <v-progress-circular
          v-if="generatingLink"
          indeterminate
          color="primary"
          class="content-absolute mt-4"
          :class="$vuetify.breakpoint.xsOnly ? '' : 'mr-14'"
        />
        <v-menu
          attach
          left
          offset-y
          class="ml-5"
          :disabled="generatingLink"
          v-else
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              elevation="1"
              fab
              small
              v-bind="attrs"
              v-on="on"
              icon
              class="button-primary content-absolute mt-4"
              :class="$vuetify.breakpoint.xsOnly ? '' : 'mr-14'"
            >
              <v-icon color="primary">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="generateLink()">
              <v-list-item-title>Generar Link</v-list-item-title>
            </v-list-item>
            <v-list-item @click="updatePatient()">
              <v-list-item-title>Editar</v-list-item-title>
            </v-list-item>
            <v-list-item @click="openWarningMessage(true)">
              <v-list-item-title>Eliminar</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-row>
          <v-col
            cols="12"
            sm="1"
            md="2"
            lg="1"
            xl="1"
            :class="
              this.$vuetify.breakpoint.xsOnly
                ? 'd-flex justify-center align-center'
                : ''
            "
          >
            <v-avatar
              color="primary"
              :size="
                this.$vuetify.breakpoint.xsOnly
                  ? 70
                  : this.$vuetify.breakpoint.smAndDown
                  ? 50
                  : 100
              "
            >
              <span class="white--text text-md-h2 text-sm-h5 text-h4">{{
                patient.firstName[0] + patient.lastName[0]
              }}</span>
            </v-avatar>
          </v-col>
          <v-col
            cols="12"
            sm="11"
            md="10"
            lg="11"
            xl="11"
            class="d-flex align-center"
            :class="this.$vuetify.breakpoint.xsOnly ? 'justify-center' : ''"
          >
            <div class="text-md-h2 text-sm-h4 text-h5">
              {{ patient.firstName + " " + patient.lastName }}
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-card elevation="10">
        <v-tabs
          v-model="activeTab"
          center-active
          show-arrows
          class="tabs-background tabs-arrow-prev-background tabs-arrow-next-background"
          dark
        >
          <v-tab class="justify-content tabs-shadow-background">
            <v-icon left>mdi-account</v-icon>
            Información
          </v-tab>
          <v-tab class="justify-content tabs-shadow-background">
            <v-icon left>mdi-family-tree</v-icon>
            Árbol Genealógico
          </v-tab>
          <v-tab class="justify-content tabs-shadow-background">
            <v-icon left>mdi-file-document</v-icon>
            Historial
          </v-tab>
          <v-tab class="justify-content tabs-shadow-background">
            <v-icon left>mdi-folder-multiple</v-icon>
            Multimedia
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="activeTab"
          touchless
          :class="activeTab == 2 ? 'wrapper-hidden' : 'wrapper'"
          class="wrapper scroll-style"
        >
          <v-tab-item>
            <patient-info :patient="patient" />
          </v-tab-item>
          <v-tab-item>
            <family-tree-info
              @loadPatient="loadPatientsDetail"
              @changeView="changeView"
              :relatives="patient.relatives"
            />
          </v-tab-item>
          <v-tab-item>
            <patient-notes
              :patientFullName="patient.firstName + '_' + patient.lastName"
              :record="patient.record"
            />
          </v-tab-item>
          <v-tab-item>
            <patient-multimedia :patient="patient" :inTab="activeTab == 3" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <success-message
        :showModal="showSuccessMessage"
        :title="successTitle"
        :message="successMessage"
        @okAction="successfulTransaction()"
      />
      <patient-modal
        v-if="showPatientModal"
        :showModal="showPatientModal"
        :titleToShow="patientModalTitle"
        :update="true"
        :formPatient="patient.formPatients[0].id"
        @closeModal="openPatientModal"
        @loadPatients="loadPatientsDetail"
      />
      <warning-message
        :loading="loadingDeletion"
        :showModal="showWarningMessage"
        ok="Eliminar"
        title="¿Desea eliminar este consultante?"
        message="La información del consultante desaparecerá. Esta acción no se podrá revertir"
        @cancelAction="openWarningMessage"
        @okAction="deletePatient"
      />
    </span>
  </div>
</template>

<script>
import PatientModal from "./PatientModal";
import PatientsDetailLoader from "@/components/common/loaders/PatientsDetailLoader";
import SuccessMessage from "@/components/common/notifications/SuccessMessage";
import WarningMessage from "@/components/common/notifications/WarningMessage";
import PatientInfo from "./PatientInfo";
import FamilyTreeInfo from "./FamilyTreeInfo";
import PatientNotes from "./PatientNotes";
import ErrorView from "@/components/common/notifications/ErrorView";
import Repository from "@/services/repositories/repositoryFactory";
import PatientMultimedia from "./PatientMultimedia.vue";
const PatientRepository = Repository.get("patient");
const FormRepository = Repository.get("form");

export default {
  name: "patients-detail",
  components: {
    "patient-info": PatientInfo,
    "warning-message": WarningMessage,
    "success-message": SuccessMessage,
    "patient-modal": PatientModal,
    "patients-detail-loader": PatientsDetailLoader,
    "error-view": ErrorView,
    "family-tree-info": FamilyTreeInfo,
    "patient-notes": PatientNotes,
    "patient-multimedia": PatientMultimedia
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      loadingDeletion: false,
      errorLoading: false,
      generatingLink: false,
      showPatientModal: false,
      showWarningMessage: false,
      patientModalTitle: "",
      patient: null,
      showSuccessMessage: false,
      successTitle: "",
      successMessage: "",
      treeView: true,
      activeTab: 1,
      items: [
        {
          text: "Consultantes",
          disabled: false,
          href: "../patients"
        },
        {
          text: "Detalle",
          disabled: false
        }
      ]
    };
  },
  methods: {
    async loadPatientsDetail() {
      this.loading = true;
      this.patient = null;
      this.patient = await PatientRepository.getPatientInfoById(
        this.id
      ).finally(() => {
        this.loading = false;
      });
    },
    openWarningMessage(visibility) {
      this.showWarningMessage = visibility;
    },
    openPatientModal(visibility) {
      this.showPatientModal = visibility;
    },
    changeView(treeView) {
      this.treeView = treeView;
    },
    async deletePatient() {
      this.successTitle = "";
      this.succesMessage = "";
      this.loadingDeletion = true;
      await PatientRepository.deletePatientById(this.patient.id)
        .then(() => {
          this.successTitle = "¡Se ha eliminado el consultante exitosamente!";
          this.succesMessage =
            "El consultante se ha eliminado y ya no visualizará su información.";
          this.showSuccessMessage = true;
        })
        .finally(() => {
          this.loadingDeletion = false;
          this.openWarningMessage(false);
          this.$router.push({ name: "patients" });
        });
    },
    updatePatient() {
      this.patientModalTitle = `Modificar consultante`;
      this.openPatientModal(true);
    },
    successfulTransaction() {
      this.showSuccessMessage = false;
    },
    async generateLink() {
      this.generatingLink = true;
      await FormRepository.generateLink(this.id)
        .then(resp => {
          this.successTitle = "¡Se ha generado el link de formulario!";
          this.successMessage =
            process.env.VUE_APP_VENUSA_LIVE_WEBSITE +
            "/form-update/" +
            resp.form_token;
          this.showSuccessMessage = true;
        })
        .finally(() => {
          this.generatingLink = false;
        });
    }
  },
  mounted() {
    this.loadPatientsDetail();
  }
};
</script>

<style scoped>
.justify-content {
  justify-content: end;
}
.wrapper {
  overflow-y: scroll !important;
  min-height: 795px !important;
  max-height: 795px !important;
}
.wrapper-hidden {
  overflow-y: hidden !important;
  min-height: 795px !important;
  max-height: 795px !important;
}
.scroll-style::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
  background-color: rgba(168, 168, 168, 0.3);
}
.scroll-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  border: solid 1px rgba(255, 255, 255, 0.4);
}
.scroll-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.4);
}
.tabs-background ::v-deep .v-tabs-bar__content {
  color: white !important;
  background: rgb(219, 120, 219);
  background: linear-gradient(
    180deg,
    rgba(219, 120, 219, 1) 12%,
    rgba(205, 140, 236, 1) 100%
  );
}
.tabs-arrow-prev-background ::v-deep .v-slide-group__prev {
  background: linear-gradient(
    180deg,
    rgba(219, 120, 219, 1) 12%,
    rgba(205, 140, 236, 1) 100%
  );
}
.tabs-arrow-next-background ::v-deep .v-slide-group__next {
  background: linear-gradient(
    180deg,
    rgba(219, 120, 219, 1) 12%,
    rgba(205, 140, 236, 1) 100%
  );
}
.button-primary {
  border: solid 2px var(--v-primary-base);
  color: var(--v-primary-base);
}
.content-relative {
  position: relative;
}
.content-absolute {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
