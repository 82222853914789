<template>
  <span class="pa-4">
    <v-container fluid class="py-0 pr-10">
      <v-row class="py-0 my-0">
        <v-col cols="12" class="py-0 my-0" align="end">
          <v-btn
            color="primary"
            v-if="!$vuetify.breakpoint.smAndDown"
            v-model="treeView"
            @click="changeView"
            fab
            medium
            dark
            ><v-icon>{{
              treeView ? "mdi-format-list-bulleted" : "mdi-family-tree"
            }}</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="pa-6" v-if="treeView">
      <v-row class="py-0">
        <v-col cols="12" class="py-0">
          <family-tree
            v-if="relatives.length"
            @selectedRelative="modifyRelative"
            :relatives="relatives"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="pa-6" v-if="!treeView">
      <v-row class="py-0">
        <v-col cols="12" class="py-0">
          <family-tree-card
            v-if="relatives.length"
            @selectedRelative="modifyRelative"
            :relatives="relatives"
          />
        </v-col>
      </v-row>
    </v-container>

    <relative-modal
      v-if="showModal"
      :showModal="showModal"
      :titleToShow="modalTitle"
      :relativeToModify="relativeToModify"
      :kinship="relativeToModify.kinship.name"
      @closeModal="openModal"
      @loadPatient="loadPatient"
    />
  </span>
</template>

<script>
import FamilyTree from "./FamilyTree";
import FamilyTreeCard from "./FamilyTreeCard";
import RelativeModal from "./RelativeModal";

export default {
  name: "family-tree-info",
  components: {
    "family-tree": FamilyTree,
    "family-tree-card": FamilyTreeCard,
    "relative-modal": RelativeModal
  },
  props: {
    relatives: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      relativesInfo: null,
      showModal: false,
      modalTitle: "",
      relativeToModify: null,
      treeView: true
    };
  },
  methods: {
    modifyRelative(relative) {
      this.relativeToModify = relative;
      this.modalTitle = `Modificar familiar - ${relative.kinship.name}`;
      this.openModal(true);
    },
    openModal(visibility) {
      this.showModal = visibility;
    },
    loadPatient() {
      this.$emit("loadPatient");
    },
    changeView() {
      this.treeView = !this.treeView;
      this.$emit("changeView", this.treeView);
    },
    windowResize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.treeView = false;
      }
    }
  },
  created() {
    window.addEventListener("resize", this.windowResize);
  },
  destroyed() {
    window.addEventListener("resize", this.windowResize);
  }
};
</script>

<style scoped></style>
