<template>
  <v-dialog
    v-model="showModal"
    transition="dialog-bottom-transition"
    persistent
    fullscreen
  >
    <v-card>
      <v-toolbar dark color="primary" class="toolbar">
        <v-btn icon dark @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ titleToShow }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="saveSection" :loading="loading"
            >Guardar</v-btn
          >
        </v-toolbar-items>
      </v-toolbar>
      <br />
      <v-card-text>
        <v-container class="mt-7">
          <v-form ref="relative">
            <v-row class="mt-7">
              <v-col cols="12">
                <h2 class="mb-5  text-sm-h4 primary--text">
                  {{ kinship }}
                </h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h1 class="mb-5  text-h6 primary--text">
                  Nombre del familiar
                </h1>
                <v-text-field
                  v-model.trim="relative.name"
                  :rules="[rules.requiredText]"
                  prepend-inner-icon="mdi-format-title"
                  label="Nombre"
                  maxlength="200"
                  counter
                  outlined
                  :disabled="isPatientKinship"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <h1 class="mb-5 text-h6 primary--text">
                  Descripción del familiar
                </h1>
                <v-textarea
                  v-model.trim="relative.content"
                  :rules="[rules.requiredText]"
                  prepend-inner-icon="mdi-format-title"
                  label="Descripción"
                  maxlength="50000"
                  counter
                  outlined
                  auto-grow
                  clearable
                  :rows="10"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <success-message
        :showModal="showSuccessMessage"
        :title="successTitle"
        :message="successMessage"
        @okAction="successfulTransaction()"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import SuccessMessage from "@/components/common/notifications/SuccessMessage";
import { KINSHIPS } from "@/config/constants";

import Repository from "@/services/repositories/repositoryFactory";
const PatientRepository = Repository.get("patient");

export default {
  name: "relative-modal",
  components: {
    "success-message": SuccessMessage
  },
  props: {
    showModal: { type: Boolean, required: true },
    titleToShow: { type: String, required: true },
    kinship: { type: String, required: true },
    relativeToModify: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      rules: {
        requiredText: value =>
          /\S/.test(value) || value == "" || "No se admiten campos en blanco"
      },
      loading: false,
      showSuccessMessage: false,
      successTitle: "",
      successMessage: "",
      isPatientKinship: false,
      relative: {
        id: null,
        name: null,
        content: null
      }
    };
  },
  methods: {
    validatePatient() {
      return this.$refs.relative.validate();
    },
    loadPatient() {
      this.$emit("loadPatient");
    },
    successfulTransaction() {
      this.loadPatient();
      this.showSuccessMessage = false;
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal", false);
    },
    async saveSection() {
      this.savingQuestion = true;
      if (this.validatePatient()) {
        this.loading = true;
        await PatientRepository.updateRelative(this.relative)
          .then(() => {
            this.updatedSuccessfully();
          })
          .finally(() => {
            this.loading = false;
          });
      }
      this.savingQuestion = false;
    },
    updatedSuccessfully() {
      this.successTitle = "¡Se ha modificado al familiar exitosamente!";
      this.successMessage =
        "La la información del familiar se ha modificado y podrá ser visualizada en el árbol genealógico";
      this.showSuccessMessage = true;
    }
  },
  mounted() {
    if (this.relativeToModify) {
      this.relative.id = this.relativeToModify.id;
      this.relative.name = this.relativeToModify.name;
      this.relative.content = this.relativeToModify.content;
      if (this.relativeToModify.kinship.id === KINSHIPS.ME.id) {
        this.isPatientKinship = true;
      }
    }
  }
};
</script>

<style scoped>
.toolbar {
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;
}
</style>
