<template>
  <v-container class="pa-6" v-if="sections">
    <v-row class="py-0">
      <v-col cols="12" class="py-0" v-if="answerDate">
        Fecha de creación: {{ answerDate }}
      </v-col>
    </v-row>
    <v-row justify="center" class="py-0">
      <v-col cols="12" align="center" class="py-0">
        <v-stepper
          v-model="activeStep"
          alt-labels
          class="stepper elevation-0 pb-0 py-0"
        >
          <v-stepper-header>
            <v-stepper-step
              :complete="activeStep > 0"
              :step="0"
              v-if="!$vuetify.breakpoint.smAndDown"
              color="primary"
              class="stepper-number-color px-0 stepper-icon-color stepper-label"
            >
              <span class="primary--text">Datos Básicos</span>
            </v-stepper-step>
            <template v-for="(section, index) in sections">
              <v-divider
                color="white"
                v-if="!$vuetify.breakpoint.smAndDown"
                :key="`${index}-divider-${section.id}`"
              />
              <v-stepper-step
                v-if="!$vuetify.breakpoint.smAndDown"
                :key="`${index}-stepper-${section.id}`"
                :complete="activeStep > index + 1"
                :step="index + 1"
                color="primary"
                class="stepper-number-color  px-0 stepper-icon-color stepper-label"
              >
                <span class="primary--text">Sección {{ index + 1 }}</span>
              </v-stepper-step>
            </template>
            <div
              v-if="$vuetify.breakpoint.smAndDown"
              class="d-flex justify-center  fill-width primary--text"
            >
              <br />
              Página {{ activeStep + 1 }} de {{ sections.length + 1 }}
            </div>
            <v-stepper-items class="fill-width">
              <v-stepper-content
                class="pt-0"
                :step="0"
                :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
                ><v-container>
                  <patient-basic-data :patient="patient" />
                </v-container>
              </v-stepper-content>
              <v-stepper-content
                class="pt-0"
                :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
                v-for="(section, index) in sections"
                :key="`${index}-content-${section.id}`"
                :step="index + 1"
              >
                <patient-form-section-data
                  ref="patient-form-data"
                  :section="section"
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper-header>
        </v-stepper>
      </v-col>
    </v-row>
    <v-row class="pb-7 px-4 ">
      <v-col
        :class="$vuetify.breakpoint.smAndDown ? 'mb-6 pb-0' : 'py-0'"
        align="end"
        cols="12"
        sm="6"
      >
        <v-btn
          v-if="activeStep != 0"
          block
          @click="previousSection()"
          color="gray"
        >
          Anterior
        </v-btn>
      </v-col>
      <v-col
        :class="$vuetify.breakpoint.smAndDown ? '' : 'py-0'"
        align="end"
        cols="12"
        sm="6"
      >
        <v-btn
          v-if="activeStep != this.sections.length"
          @click="nextSection()"
          color="primary"
          block
          class="button-text-color"
        >
          Continuar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PatientBasicData from "./PatientBasicData";
import PatientFormSectionData from "./PatientFormSectionData";

export default {
  name: "patient-info",
  components: {
    "patient-basic-data": PatientBasicData,
    "patient-form-section-data": PatientFormSectionData
  },
  props: {
    patient: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeStep: 0,
      sections: null,
      answerDate: ""
    };
  },
  methods: {
    async nextSection() {
      this.activeStep++;
      if (this.$refs["patient-form-data"].length) {
        this.$refs["patient-form-data"].forEach(component => {
          component.forceRerender();
        });
      }
    },
    previousSection() {
      this.activeStep--;
      if (this.$refs["patient-form-data"].length) {
        this.$refs["patient-form-data"].forEach(component => {
          component.forceRerender();
        });
      }
    }
  },
  mounted() {
    if (this.patient) {
      if (this.patient?.formPatients?.length) {
        this.sections = this.patient?.formPatients[0]?.form?.sections;
        let date = new Date(this.patient.formPatients[0].answerDate);
        this.answerDate =
          (date.getDate() < 10 ? "0" : "") +
          date.getDate() +
          "/" +
          (date.getMonth() + 1 < 10 ? "0" : "") +
          (date.getMonth() + 1) +
          "/" +
          date.getFullYear();
      }
      this.sections = this.sections ? this.sections : [];
    }
  }
};
</script>

<style scoped>
.stepper {
  background: white;
}
.stepper-number-color ::v-deep .v-stepper__step__step {
  color: white !important;
}
.stepper-icon-color ::v-deep i {
  color: white !important;
}
.stepper-label ::v-deep .v-stepper__label {
  text-align: center !important;
}
.fill-width {
  width: 100%;
}
</style>
